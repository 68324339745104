// File: src\utils\pairAddressHelper.ts

import { Contract, ethers } from 'ethers';
import { FACTORY_ADDRESS, Token } from 'sdk';
import IUniswapV2Factory from '@uniswap/v2-core/build/IUniswapV2Factory.json';

// const PAIR_ADDRESS_MAP: any = {};
const provider = new ethers.providers.JsonRpcProvider('https://mainnet-1.rpc.banelabs.org');
const factoryContract = new Contract(FACTORY_ADDRESS, IUniswapV2Factory.abi, provider);

export function updatePairCache(tokenA: string, tokenB: string, pairAddress: string) {
  const key1 = `${tokenA}-${tokenB}`;
  window.localStorage.setItem(key1, pairAddress);
  const key2 = `${tokenB}-${tokenA}`;
  window.localStorage.setItem(key2, pairAddress);
}

export function getPairFromCache(tokenA: string, tokenB: string) {
  const key1 = `${tokenA}-${tokenB}`;
  const address = localStorage.getItem(key1);
  return address || '';
}

export function generatePairAddress(tokenA: Token, tokenB: Token, callback: () => void) {
  if (tokenA && tokenB) {
    const cachedPairAddress = getPairFromCache(tokenA.address, tokenB.address);
    if (cachedPairAddress) {
      callback();
      return;
    }

    factoryContract
      .getPair(tokenA.address, tokenB.address)
      .then((value: string) => {
        updatePairCache(tokenA.address, tokenB.address, value);
        callback();
      })
      .catch((e: any) => {
        console.error('Error while fetchin pair address', e);
        // Run call back for just fail safe purpose
        callback();
      });
  } else {
    callback();
  }
}

const CACHED_PROMISES: Record<string, Promise<string>> = {};
export async function generatePairAddressAsync(tokenA: Token, tokenB: Token): Promise<string> {
  const cachedPairAddress: string = getPairFromCache(tokenA.address, tokenB.address);
  if (cachedPairAddress) {
    return cachedPairAddress;
  }
  const key = `${tokenA.address}-${tokenB.address}`;

  if (!!CACHED_PROMISES[key]) {
    return CACHED_PROMISES[key];
  }

  CACHED_PROMISES[key] = factoryContract
    .getPair(tokenA.address, tokenB.address)
    .then((value: string) => {
      updatePairCache(tokenA.address, tokenB.address, value);
      return value;
    })
    .catch((e: any) => {
      console.error('Error while fetchin pair address', e);
      // Run call back for just fail safe purpose
      return e;
    });

  return CACHED_PROMISES[key];
}

import React, { useEffect, useState, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Modal from '../Modal';
import { ExternalLink } from '../../theme';
import { Text } from 'rebass';
import { CloseIcon, CustomLightSpinner } from '../../theme/components';
import { RowBetween } from '../Row';
import { ArrowUpCircle, AlertCircle } from 'react-feather';
import { ButtonPrimary } from '../Button';
import { AutoColumn, ColumnCenter } from '../Column';
import Circle from '../../assets/images/blue-loader.svg';
import { getChainById } from '../../constants/chainList';

const Wrapper = styled.div`
  width: 100%;
`;
const Section = styled(AutoColumn)`
  padding: 24px;
`;

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 60px 0;
`;

interface BridgeStatus {
  transactionHash: string;
  status: 'pending' | 'completed' | 'failed';
  chainType: 'origin' | 'destination';
  fromAddress: string;
  toAddress: string;
  amount: string;
  timestamp: string;
  outputTxHash: string;
  errorMessage?: string;
}

interface BridgeCompletionModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  transactionHash: string;
}

export default function BridgeCompletionModal({
  isOpen,
  onDismiss,
  transactionHash,
}: BridgeCompletionModalProps) {
  const theme = useContext(ThemeContext);
  const [bridgeStatus, setBridgeStatus] = useState<BridgeStatus | null>(null);

  const fetchBridgeStatus = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/bridge-status/${transactionHash}`);
      if (!response.ok) throw new Error('Failed to fetch bridge status');
      const data: BridgeStatus = await response.json();
      setBridgeStatus(data);
      return data.status;
    } catch (error) {
      console.error('Error fetching bridge status:', error);
      return 'error';
    }
  };

  useEffect(() => {
    if (isOpen && transactionHash) {
      setBridgeStatus(null);
      
      const checkStatus = async () => {
        const status = await fetchBridgeStatus();
        if (status !== 'completed' && status !== 'failed') {
          setTimeout(checkStatus, 30000); // Check again after 30 seconds
        }
      };
      checkStatus();
    }
  }, [isOpen, transactionHash]);
  
  const getExplorerLink = (chainType: 'origin' | 'destination', outputTxHash: string) => {
    const chainId = chainType === 'origin' ? 'neox' : 'bsc';
    const chain = getChainById(chainId);
    if (chain) {
      return `${chain.explorer}/tx/${outputTxHash}`;
    }
    return '#';
  };

  const renderContent = () => {
    if (!bridgeStatus) {
      return (
        <Section>
          <RowBetween>
            <div />
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <ConfirmedIcon>
            <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
          </ConfirmedIcon>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={500} fontSize={20}>
              Waiting Chain Confirmation
            </Text>
            <Text fontSize={14} color={theme.text2} textAlign="center">
              Don't close the modal, or switch chain.
            </Text>
          </AutoColumn>
        </Section>
      );
    }

    if (bridgeStatus.status === 'pending') {
      return (
        <Section>
          <RowBetween>
            <div />
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <ConfirmedIcon>
            <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
          </ConfirmedIcon>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={500} fontSize={20}>
              Bridge In Progress
            </Text>
            <Text fontSize={14} color={theme.text2} textAlign="center">
              Your assets are being transferred. This may take a few minutes.
            </Text>
          </AutoColumn>
        </Section>
      );
    }

    if (bridgeStatus.status === 'failed') {
      return (
        <Section>
          <RowBetween>
            <div />
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <ConfirmedIcon>
            <AlertCircle strokeWidth={0.5} size={90} color={theme.red1} />
          </ConfirmedIcon>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={500} fontSize={20} color={theme.red1}>
              Bridge Failed
            </Text>
            <Text fontSize={14} color={theme.text2} textAlign="center">
              {bridgeStatus.errorMessage || 'An error occurred during the bridge process. Contact admin providing txhash.'}
            </Text>
            <ExternalLink href={getExplorerLink(bridgeStatus.chainType, bridgeStatus.transactionHash)}>
              <Text fontWeight={500} fontSize={14} color={theme.primary1}>
                View on Explorer
              </Text>
            </ExternalLink>
            <ButtonPrimary onClick={onDismiss} style={{ marginTop: '20px' }}>
              Close
            </ButtonPrimary>
          </AutoColumn>
        </Section>
      );
    }

    return (
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <ConfirmedIcon>
          <ArrowUpCircle strokeWidth={0.5} size={90} color={theme.primary1} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={500} fontSize={20}>
            Bridge Completed
          </Text>
          <Text fontSize={14} color={theme.text2} textAlign="center">
            {`${bridgeStatus.amount} transferred from ${bridgeStatus.fromAddress.slice(0, 6)}...${bridgeStatus.fromAddress.slice(-4)} to ${bridgeStatus.toAddress.slice(0, 6)}...${bridgeStatus.toAddress.slice(-4)}`}
          </Text>
          <ExternalLink href={getExplorerLink(bridgeStatus.chainType, bridgeStatus.outputTxHash)}>
            <Text fontWeight={500} fontSize={14} color={theme.primary1}>
              View on Explorer
            </Text>
          </ExternalLink>
          <ButtonPrimary onClick={onDismiss} style={{ marginTop: '20px' }}>
            Close
          </ButtonPrimary>
        </AutoColumn>
      </Section>
    );
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <Wrapper>{renderContent()}</Wrapper>
    </Modal>
  );
}
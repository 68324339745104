// File: src\components\CurrencyLogo\index.tsx

import { Currency, ETHER, Token } from 'sdk';
import React, { useMemo } from 'react';
import styled from 'styled-components';

// import EthereumLogo from '../../assets/images/doge-logo.png';

import GAS from '../../assets/images/token-list/gas.png';
import WGAS from '../../assets/images/token-list/wgas.png';
import ZKDEFI from '../../assets/images/token-list/zkdefi.png';
import USDT from '../../assets/images/token-list/usdt.png';
import USDC from '../../assets/images/token-list/usdc.png';
import WBTC from '../../assets/images/token-list/wbtc.png';
import BORK from '../../assets/images/token-list/BORK.png';
import NUSD from '../../assets/images/token-list/nusd.png';
import CAT from '../../assets/images/token-list/kat555.png';
import NDMEME from '../../assets/images/token-list/NDMEME.png';
import MTIX from '../../assets/images/token-list/mtix.png';

import useHttpLocations from '../../hooks/useHttpLocations';
import { WrappedTokenInfo } from '../../state/lists/hooks';
import Logo from '../Logo';

const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${address}/logo.png`;

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`;

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`;

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency;
  size?: string;
  style?: React.CSSProperties;
}) {
  const url = currency instanceof WrappedTokenInfo ? currency.logoURI : '';
  const url1 = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined);
  const uriLocations = url?.startsWith('images') ? url : url1;

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return [];

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, url?.startsWith('images') ? url : getTokenLogoURL(currency.address)];
      }

      return [getTokenLogoURL(currency.address)];
    }
    return [];
  }, [currency, uriLocations, url]);

  if (currency === ETHER) {
    return <StyledEthereumLogo src={GAS} size={size} style={style} />;
  }

  if (currency?.name === 'WGAS') {
    return <StyledEthereumLogo src={WGAS} size={size} style={style} />;
  }

  if (currency?.name === 'zkDefi') {
    return <StyledEthereumLogo src={ZKDEFI} size={size} style={style} />;
  }

  if (currency?.name === 'USDC') {
    return <StyledEthereumLogo src={USDC} size={size} style={style} />;
  }

  if (currency?.symbol === 'USDC') {
    return <StyledEthereumLogo src={USDC} size={size} style={style} />;
  }

  if (currency?.name === 'USDT') {
    return <StyledEthereumLogo src={USDT} size={size} style={style} />;
  }

  if (currency?.symbol === 'USDT') {
    return <StyledEthereumLogo src={USDT} size={size} style={style} />;
  }

  if (currency?.symbol === 'NUSD') {
    return <StyledEthereumLogo src={NUSD} size={size} style={style} />;
  }

  if (currency?.name === 'Ether') {
    return <StyledEthereumLogo src={GAS} size={size} style={style} />;
  }

  if (currency?.name === 'GAS') {
    return <StyledEthereumLogo src={GAS} size={size} style={style} />;
  }

  if (currency?.name === 'WBTC') {
    return <StyledEthereumLogo src={WBTC} size={size} style={style} />;
  }

  if (currency?.name === 'BORK') {
    return <StyledEthereumLogo src={BORK} size={size} style={style} />;
  }

  if (currency?.name === 'X-Cat') {
    return <StyledEthereumLogo src={CAT} size={size} style={style} />;
  }

  if (currency?.name === 'THE MATRIX') {
    return <StyledEthereumLogo src={MTIX} size={size} style={style} />;
  }

  if (currency?.name === 'NeoDashboard Meme') {
    return <StyledEthereumLogo src={NDMEME} size={size} style={style} />;
  }




  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />;
}

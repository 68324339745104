// src/constants/chainList.ts

import neoxIcon from '../assets/images/chain-list/neox.png';
import bscIcon from '../assets/images/chain-list/bsc.png';

interface TokenInfo {
  symbol: string;
  name: string;
  address: string;
  decimals: number;
}

export interface Chain {
  id: string;
  name: string;
  icon: string;
  bridge: string;
  rpcurl: string,
  chainid: number,
  explorer: string,
  gastoken: string,
  token: TokenInfo;
}

export const chainList: Chain[] = [
  {
    id: 'neox',
    name: 'Neo X',
    icon: neoxIcon,
    bridge: '0xb8625b0e60D35E01e335c22dFfd8d33b08B69Aa7',
    rpcurl: 'https://mainnet-1.rpc.banelabs.org/',
    chainid: 47763,
    explorer: 'https://xexplorer.neo.org/',
    gastoken: 'GAS',
    token: {
      symbol: 'NUSD',
      name: 'NUSD Token',
      address: '0x561Ef01198DcDE461DC74F19F5DCBaf814EEbfF9',
      decimals: 18
    }
  },
  {
    id: 'bsc',
    name: 'BSC',
    icon: bscIcon,
    bridge: '0xbe3ed4aaa289ad65fbd77c02a3ce29aa3589214f',
    rpcurl: 'https://binance.llamarpc.com	',
    chainid: 56,
    explorer: 'https://bscscan.com',
    gastoken: 'BNB',
    token: {
      symbol: 'USDC',
      name: 'USDC Token',
      address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      decimals: 18
    }
  },
  // Add more chains as needed
];

export const getChainById = (id: string): Chain | undefined => {
  return chainList.find(chain => chain.id === id);
};
// File: src\pages\Bridge\index.tsx
import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react';
import { CurrencyAmount, JSBI, Token } from 'sdk';
import { ThemeContext } from 'styled-components';
import { ButtonError, ButtonPrimary } from '../../components/Button';
import Card from '../../components/Card';
import { AutoColumn } from '../../components/Column';
import BridgeInputPanel from '../../components/BridgeInputPanel';
import { SwapPoolTabs } from '../../components/NavigationTabs';
import { RowBetween } from '../../components/Row';
import { BottomGrouping, Wrapper } from '../../components/swap/styleds';
import AppBody from '../AppBody';
import { useActiveWeb3React } from '../../hooks';
import BridgeHeader from '../../components/Bridge/BridgeHeader';
import { useWalletModalToggle } from '../../state/application/hooks';
import { useDarkModeManager } from '../../state/user/hooks';
import { Field } from '../../state/swap/actions';
import { useSwapActionHandlers, useSwapState } from '../../state/swap/hooks';
import { TYPE } from '../../theme';
import Arrow from '../../assets/svg/light_arrow.svg';
import DarkArrow from '../../assets/svg/dark_arrow.svg';
import LeftPages from '../../components/swap/LeftPages';
import { chainList, Chain } from '../../constants/chainList';
import { ethers } from 'ethers';
import BridgeCompletionModal from 'components/BridgeCompletionModal';
import OriginBridgeABI from '../../constants/abis/OriginBridge.json';
import DestinationBridgeABI from '../../constants/abis/DestinationBridge.json';
import ERC20ABI from '../../constants/abis/erc20.json';
import TopSlider from '../../components/banner/index';
import { Settings } from 'react-feather';
import { useBridgeLimits } from '../../hooks/useBridgeLimits';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTokenBalance } from '../../state/wallet/hooks';
import { maxAmountSpend } from 'utils/maxAmountSpend';

export default function Bridge() {
  const { account, library } = useActiveWeb3React();
  const theme = useContext(ThemeContext);
  const toggleWalletModal = useWalletModalToggle();
  const { typedValue } = useSwapState();
  const { onUserInput } = useSwapActionHandlers();
  const [darkMode] = useDarkModeManager();

  const [fromChain, setFromChain] = useState<Chain>(chainList[0]);
  const [toChain, setToChain] = useState<Chain>(chainList[1]);

  const [bridgeModalOpen, setBridgeModalOpen] = useState(false);
  const [bridgeTransactionHash, setBridgeTransactionHash] = useState('');
  const [isTransactionPending, setIsTransactionPending] = useState(false);

  const [destinationAddress, setDestinationAddress] = useState<string>('');
  const [isEditingDestination, setIsEditingDestination] = useState(false);
  const [isValidDestination, setIsValidDestination] = useState(true);

  const getLimits = useBridgeLimits();
  const { minAmount, maxAmount, isLoading } = getLimits(fromChain.id);

  const fromToken = useMemo(() => {
    const { token } = fromChain;
    return new Token(fromChain.chainid, token.address, token.decimals, token.symbol, token.name);
  }, [fromChain]);

  const fromTokenBalance = useTokenBalance(account ?? undefined, fromToken);

  const toToken = useMemo(() => {
    const { token } = toChain;
    return new Token(toChain.chainid, token.address, token.decimals, token.symbol, token.name);
  }, [toChain]);

  const handleTypeInput = useCallback(
    (value: string) => {
      let newValue = value;
      if (value !== '' && value !== '.' && !isNaN(parseFloat(value))) {
        const parsedValue = ethers.utils.parseUnits(value, fromToken.decimals);
        const bigIntValue = JSBI.BigInt(parsedValue.toString());
        
        if (JSBI.lessThan(bigIntValue, minAmount) && !value.endsWith('.')) {
          newValue = ethers.utils.formatUnits(minAmount.toString(), fromToken.decimals);
        } else if (JSBI.greaterThan(bigIntValue, maxAmount)) {
          newValue = ethers.utils.formatUnits(maxAmount.toString(), fromToken.decimals);
        }
      }
      onUserInput(Field.INPUT, newValue);
    },
    [onUserInput, fromToken.decimals, minAmount, maxAmount]
  );


  const parsedAmount = useMemo(() => {
    if (typedValue === '' || typedValue === '.') return CurrencyAmount.ether('0');
    try {
      const valueAsEther = ethers.utils.parseUnits(typedValue, fromToken.decimals);
      return CurrencyAmount.ether(valueAsEther.toString());
    } catch (error) {
      console.error('Failed to parse input amount', error);
      return CurrencyAmount.ether('0');
    }
  }, [typedValue, fromToken.decimals]);
  

  const handleFromChainChange = async (selectedChain: Chain) => {
    setFromChain(selectedChain);
    if (selectedChain.id === toChain.id) {
      const newToChain = chainList.find(chain => chain.id !== selectedChain.id);
      if (newToChain) setToChain(newToChain);
    }
  
    // Switch the connector chain
    if (library?.provider && typeof library.provider.request === 'function') {
      try {
        await library.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${selectedChain.chainid.toString(16)}` }],
        });
      } catch (switchError) {
        if (typeof switchError === 'object' && switchError !== null && 'code' in switchError) {
          // This error code indicates that the chain has not been added to MetaMask.
          if ((switchError as { code: number }).code === 4902) {
            try {
              await library.provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: `0x${selectedChain.chainid.toString(16)}`,
                    chainName: selectedChain.name,
                    rpcUrls: [selectedChain.rpcurl],
                    nativeCurrency: {
                      name: selectedChain.gastoken,
                      symbol: selectedChain.gastoken,
                      decimals: 18,
                    },
                    blockExplorerUrls: [selectedChain.explorer],
                  },
                ],
              });
            } catch (addError) {
              console.error('Failed to add the network:', addError);
            }
          } else {
            console.error('Failed to switch networks:', switchError);
          }
        }
      }
    }
  };
  

  const handleToChainChange = (selectedChain: Chain) => {
    if (selectedChain.id !== fromChain.id) {
      setToChain(selectedChain);
    }
  };

  const handleDestinationAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAddress = event.target.value;
    const isValid = isValidAddress(newAddress);
    setIsValidDestination(isValid);
    if (isValid) {
      setDestinationAddress(newAddress);
    }
  };

  
  const toggleEditDestination = () => {
    setIsEditingDestination(!isEditingDestination);
  };

  const isValidAddress = (address: string) => {
    return ethers.utils.isAddress(address);
  };

  const handleSwitchChains = useCallback(() => {
    const newFromChain = toChain;
    const newToChain = fromChain;
    setFromChain(newFromChain);
    setToChain(newToChain);
    handleFromChainChange(newFromChain);
  }, [fromChain, toChain, handleFromChainChange]);




  const handleBridge = useCallback(async () => {
    if (!parsedAmount || !account || !library || !fromChain || !toChain || !isValidAddress(destinationAddress)) return;
    
    try {
      setIsTransactionPending(true);
      const signer = library.getSigner(account);
      
      // Determine which bridge contract to use based on the fromChain
      const bridgeContract = new ethers.Contract(
        fromChain.bridge,
        fromChain.id === 'neox' ? DestinationBridgeABI : OriginBridgeABI,
        signer as ethers.Signer
      );
      
      // Use the token from the fromChain for deposits
      const fromTokenContract = new ethers.Contract(fromChain.token.address, ERC20ABI, signer as ethers.Signer);
      
      const amount = parsedAmount.raw.toString();
      
      // Check balance
      const balance = await fromTokenContract.balanceOf(account);
      if (balance.lt(amount)) {
        throw new Error('Insufficient balance');
      }
      
      // Check and set allowance if needed
      const allowance = await fromTokenContract.allowance(account, bridgeContract.address);
      if (allowance.lt(amount)) {
        // Approve only the necessary amount
        const approveTx = await fromTokenContract.approve(bridgeContract.address, amount);
        await approveTx.wait();
      }

      const receivingAddress = destinationAddress || account;
      
      // Call the deposit function on the correct bridge
      const tx = await bridgeContract.deposit(
        fromChain.token.address,
        amount,
        receivingAddress 
      );
      
      console.log('Bridge transaction sent:', tx.hash);
      setBridgeTransactionHash(tx.hash);
      setBridgeModalOpen(true);
      
      await tx.wait();
      
      console.log('Bridge transaction successful:', tx.hash);
    } catch (error) {
      console.error('Bridge transaction failed:', error);
      // Here you can add logic to show an error message to the user
    } finally {
      setIsTransactionPending(false);
    }
  }, [parsedAmount, account, library, fromChain, toChain, destinationAddress]);


  
  const handleCloseModal = useCallback(() => {
    setBridgeModalOpen(false);
    setBridgeTransactionHash('');
  }, []);
  

  useEffect(() => {
    if (account) {
      setDestinationAddress(account);
    }
  }, [account]);

  const handleMaxInput = useCallback(() => {
    if (fromTokenBalance) {
      const maxAmount = maxAmountSpend(fromTokenBalance);
      if (maxAmount) {
        let adjustedAmount = JSBI.BigInt(maxAmount.raw.toString());
        
        if (JSBI.lessThan(adjustedAmount, JSBI.BigInt(minAmount.toString()))) {
          adjustedAmount = JSBI.BigInt(0);
        } else if (JSBI.greaterThan(adjustedAmount, JSBI.BigInt(maxAmount.toString()))) {
          adjustedAmount = JSBI.BigInt(maxAmount.toString());
        }
  
        onUserInput(Field.INPUT, ethers.utils.formatUnits(adjustedAmount.toString(), fromToken.decimals));
      }
    }
  }, [fromTokenBalance, onUserInput, minAmount, maxAmount, fromToken.decimals]);

  const inputStyle = {
    width: '200px',
    marginLeft: '10px',
    backgroundColor: 'transparent',
    color: darkMode ? '#ffffff' : '#000000', // white text in dark mode, black text in light mode
    border: 'none', // Remove all borders
    borderBottom: `1px solid ${isValidDestination ? 'grey' : 'red'}`,
    outline: 'none', // Remove the default outline on focus
  };

  return (
    <>
      <div className='topSlider'>
        <TopSlider/>
      </div>
      <div className="swap_inner">
        <LeftPages />
        <SwapPoolTabs active={'bridge'} />
        <AppBody>
          <BridgeHeader />
          <Wrapper id="swap-page" className="token_box_main">
            <AutoColumn gap={'md'} className="token_input_box">
              <div className="mainInputbx">
                <BridgeInputPanel
                  label={'From'}
                  value={typedValue}
                  showMaxButton={true}
                  currency={fromToken}
                  onUserInput={handleTypeInput}
                  onMax={handleMaxInput}
                  id="swap-currency-input"
                  chainList={chainList}
                  selectedChain={fromChain}
                  onChainSelect={handleFromChainChange}
                />
                <BridgeInputPanel
                  value={typedValue}
                  onUserInput={handleTypeInput}
                  label={'To'}
                  showMaxButton={false}
                  currency={toToken}
                  id="swap-currency-output"
                  chainList={chainList.filter(chain => chain.id !== fromChain.id)}
                  selectedChain={toChain}
                  onChainSelect={handleToChainChange}  
                />
                <div className="dow arow_btn" onClick={handleSwitchChains}>
                  <img width={'55px'} height={'24px'} src={darkMode ? DarkArrow : Arrow} alt="logo" />
                </div>
              </div>
            </AutoColumn>
          </Wrapper>

          <Card padding={'0px'} borderRadius={'20px'} className="inner_tx_box">
            <AutoColumn gap="2px" style={{ padding: '15px 0px 3px 0px' }}>
              <RowBetween align="center">
                <TYPE.body fontWeight={500} fontSize={14} color={theme.text3}>
                  Receiver address
                </TYPE.body>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <Settings
                    size={16}
                    onClick={toggleEditDestination}
                    style={{ cursor: 'pointer', marginRight: '5px', color:'grey' }}
                  />
                  <TYPE.body fontWeight={500} fontSize={14} color={theme.text6}>
                    {isEditingDestination ? (
                      <input
                        type="text"
                        value={destinationAddress}
                        onChange={handleDestinationAddressChange}
                        style={inputStyle}
                      />
                    ) : (
                      `${destinationAddress.slice(0, 6)}...${destinationAddress.slice(-4)}`
                    )}
                  </TYPE.body>
                </div>
              </RowBetween>
              <RowBetween align="center">
                <TYPE.body fontWeight={500} fontSize={14} color={theme.text3}>
                  Bridge limit
                </TYPE.body>
                {isLoading ? (
                  <Skeleton 
                    width={150} 
                    height={25} 
                    animation="wave"
                    style={{ 
                      backgroundColor: 'rgba(200, 200, 200, 0.2)',
                      borderRadius: '4px'
                    }}
                  />
                ) : (
                  <TYPE.body fontWeight={500} fontSize={14} color={theme.text6}>
                    {minAmount && maxAmount
                      ? `${Math.round(parseFloat(ethers.utils.formatUnits(minAmount.toString(), fromToken.decimals)))} - ${Math.round(parseFloat(ethers.utils.formatUnits(maxAmount.toString(), fromToken.decimals)))} ${fromToken.symbol}`
                      : 'N/A'}
                  </TYPE.body>
                )}
              </RowBetween>
              <RowBetween align="center">
                <TYPE.body fontWeight={500} fontSize={14} color={theme.text3}>
                  Destination time
                </TYPE.body>
                <TYPE.body fontWeight={500} fontSize={14} color={theme.text6}>
                  3-5 mins
                </TYPE.body>
              </RowBetween>
            </AutoColumn>
          </Card>
          <BottomGrouping className="amount_btn">
            {!account ? (
              <ButtonPrimary onClick={toggleWalletModal} style={{ background: theme.primary1 }} color={theme.text7}>
                Connect Wallet
              </ButtonPrimary>
            ) : (
              <ButtonError
                onClick={handleBridge}
                id="bridge-button"
                disabled={!parsedAmount || parsedAmount.equalTo(JSBI.BigInt(0)) || isTransactionPending || !isValidDestination}
                >
                <TYPE.body fontSize={20} fontWeight={500}>
                  {isTransactionPending ? 'Waiting for confirmation...' : 'Bridge'}
                </TYPE.body>
              </ButtonError>
            )}
          </BottomGrouping>
          <BridgeCompletionModal
            isOpen={bridgeModalOpen}
            onDismiss={handleCloseModal}
            transactionHash={bridgeTransactionHash} 
          />
        </AppBody>
      </div>
    </>
  );
}
// File: src\pages\PoolFinder\index.tsx

import { Currency, ETHER, JSBI, TokenAmount } from 'sdk';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Plus } from 'react-feather';
import { Text } from 'rebass';
import { ThemeContext } from 'styled-components';
import { ButtonDropdownLight } from '../../components/Button';
import { LightCard } from '../../components/Card';
import { AutoColumn, ColumnCenter } from '../../components/Column';
import CurrencyLogo from '../../components/CurrencyLogo';
import { FindPoolTabs } from '../../components/NavigationTabs';
import { MinimalPositionCard } from '../../components/PositionCard';
import Row from '../../components/Row';
import CurrencySearchModal from '../../components/SearchModal/CurrencySearchModal';
import { PairState, usePair } from '../../data/Reserves';
import { useActiveWeb3React } from '../../hooks';
import { usePairAdder } from '../../state/user/hooks';
import { useTokenBalance } from '../../state/wallet/hooks';
import { StyledInternalLink } from '../../theme';
import { currencyId } from '../../utils/currencyId';
import AppBody from '../AppBody';
import { Dots } from '../Pool/styleds';
import LeftPages from 'components/swap/LeftPages';

enum Fields {
  TOKEN0 = 0,
  TOKEN1 = 1,
}

export default function PoolFinder() {
  const { account } = useActiveWeb3React();
  const theme = useContext(ThemeContext);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [activeField, setActiveField] = useState<number>(Fields.TOKEN1);

  const [currency0, setCurrency0] = useState<Currency | null>(ETHER);
  const [currency1, setCurrency1] = useState<Currency | null>(null);

  const [pairState, pair] = usePair(currency0 ?? undefined, currency1 ?? undefined);
  const addPair = usePairAdder();
  useEffect(() => {
    if (pair) {
      addPair(pair);
    }
  }, [pair, addPair]);

  const validPairNoLiquidity: boolean =
    pairState === PairState.NOT_EXISTS ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.raw, JSBI.BigInt(0)) &&
        JSBI.equal(pair.reserve1.raw, JSBI.BigInt(0))
    );

  const position: TokenAmount | undefined = useTokenBalance(account ?? undefined, pair?.liquidityToken);
  const hasPosition = Boolean(position && JSBI.greaterThan(position.raw, JSBI.BigInt(0)));

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (activeField === Fields.TOKEN0) {
        setCurrency0(currency);
      } else {
        setCurrency1(currency);
      }
    },
    [activeField]
  );

  const handleSearchDismiss = useCallback(() => {
    setShowSearch(false);
  }, [setShowSearch]);

  const prerequisiteMessage = (
    <div className="lightCard">
      <Text textAlign="center" className="defTxt">
        {!account ? 'Connect to a wallet to find pools' : 'Select a token to find your liquidity.'}
      </Text>
    </div>
  );

  return (
    <>
      <div className="swap_inner">
        <LeftPages />
        <AppBody>
          <FindPoolTabs />
          <AutoColumn gap="md">
            <div className="imprtBx">
              <ButtonDropdownLight
                onClick={() => {
                  setShowSearch(true);
                  setActiveField(Fields.TOKEN0);
                }}
              >
                {currency0 ? (
                  <Row>
                    <CurrencyLogo currency={currency0} />
                    <Text fontWeight={500} fontSize={14} marginLeft={'12px'}>
                      {currency0.symbol}
                    </Text>
                  </Row>
                ) : (
                  <Text fontWeight={500} fontSize={14} marginLeft={'12px'}>
                    Select a Token
                  </Text>
                )}
              </ButtonDropdownLight>

              <ColumnCenter className="zupIndex">
                <div className="plusBtn">
                  <Plus size="16" color={theme.text1} />
                </div>
              </ColumnCenter>

              <ButtonDropdownLight
                onClick={() => {
                  setShowSearch(true);
                  setActiveField(Fields.TOKEN1);
                }}
              >
                {currency1 ? (
                  <Row>
                    <CurrencyLogo currency={currency1} />
                    <Text fontWeight={500} fontSize={14} marginLeft={'12px'}>
                      {currency1.symbol}
                    </Text>
                  </Row>
                ) : (
                  <Text fontWeight={500} fontSize={14} marginLeft={'12px'}>
                    Select a Token
                  </Text>
                )}
              </ButtonDropdownLight>
            </div>

            {hasPosition && (
              <ColumnCenter
                style={{ justifyItems: 'center', backgroundColor: '', padding: '12px 0px', borderRadius: '12px' }}
              >
                <Text textAlign="center" fontWeight={500}>
                  Pool Found!
                </Text>
                <StyledInternalLink to={`/pool`}>
                  <Text textAlign="center">Manage this pool.</Text>
                </StyledInternalLink>
              </ColumnCenter>
            )}

            {currency0 && currency1 ? (
              pairState === PairState.EXISTS ? (
                hasPosition && pair ? (
                  <MinimalPositionCard pair={pair} border="1px solid #CED0D9" />
                ) : (
                  <LightCard padding="45px 10px">
                    <AutoColumn gap="sm" justify="center">
                      <Text textAlign="center">You don’t have liquidity in this pool yet.</Text>
                      <StyledInternalLink to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                        <Text textAlign="center">Add liquidity.</Text>
                      </StyledInternalLink>
                    </AutoColumn>
                  </LightCard>
                )
              ) : validPairNoLiquidity ? (
                <LightCard padding="45px 10px">
                  <AutoColumn gap="sm" justify="center">
                    <Text textAlign="center">No pool found.</Text>
                    <StyledInternalLink to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                      Create pool.
                    </StyledInternalLink>
                  </AutoColumn>
                </LightCard>
              ) : pairState === PairState.INVALID ? (
                <LightCard padding="45px 10px">
                  <AutoColumn gap="sm" justify="center">
                    <Text textAlign="center" fontWeight={500}>
                      Invalid pair.
                    </Text>
                  </AutoColumn>
                </LightCard>
              ) : pairState === PairState.LOADING ? (
                <LightCard padding="45px 10px">
                  <AutoColumn gap="sm" justify="center">
                    <Text textAlign="center">
                      Loading
                      <Dots />
                    </Text>
                  </AutoColumn>
                </LightCard>
              ) : null
            ) : (
              prerequisiteMessage
            )}
          </AutoColumn>

          <CurrencySearchModal
            isOpen={showSearch}
            onCurrencySelect={handleCurrencySelect}
            onDismiss={handleSearchDismiss}
            showCommonBases
            selectedCurrency={(activeField === Fields.TOKEN0 ? currency1 : currency0) ?? undefined}
          />
        </AppBody>
      </div>
    </>
  );
}

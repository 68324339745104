// File: src\theme\index.tsx

import React, { useMemo } from 'react';
// import { transparentize } from 'polished';
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme,
} from 'styled-components';
import { useIsDarkMode } from '../state/user/hooks';
import { Text, TextProps } from 'rebass';
import { Colors } from './styled';

export * from './components';

const MEDIA_WIDTHS = {
  upToExtraSm: 575,
  upToExtraSmall: 540,
  upToSmall: 767,
  upToMedium: 991,
  upToLarge: 1280,
  upToXLarge: 1440,
};

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    (accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `;
    return accumulator;
  },
  {}
) as any;

const white = '#FFFFFF';
const black = '#000000';

export function colors(darkMode: boolean): Colors {
  return {
    // base
    white,
    black,

    imageBg: darkMode ? '../../images/dark_bg.jpg' : '../../images/light_bg.jpg',

    // text
    text1: darkMode ? '#FFFFFF' : '#000000',
    text2: darkMode ? '#C3C5CB' : '#565A69',
    text3: darkMode ? '#6C7284' : '#888D9B',
    text4: darkMode ? '#565A69' : '#C3C5CB',
    text5: darkMode ? '#2C2F36' : '#EDEEF2',
    text6: darkMode ? '#FFFFFF' : '#161616',
    text7: darkMode ? '#1D1D1D' : '#1D1D1D',
    text8: darkMode ? '#2C2928' : '#D2D2D2',

    // backgrounds / greys
    bg1: darkMode ? '#1d1f24' : '#fafafa',
    bg2: darkMode ? '#27292e' : '#ededed',
    bg3: darkMode ? '#3a3d47' : '#e6e6e8',
    bg4: darkMode ? '#4c4f5c' : '#CED0D9',
    bg5: darkMode ? '#6C7284' : '#888D9B',
    bg6: darkMode ? '#6C7284' : '#888D9B',
    bgdef: darkMode ? 'transparent' : '#FFFFFF',
    bg7: darkMode ? '#1D1D1D' : '#F1F1F1',
    bg8: darkMode ? '#000000' : '#FFFFFF',
    bg9: darkMode ? '#1D1D1D' : '#FFFFFF',
    bg10: darkMode ? '#161616' : '#FFFFFF',
    bg11: darkMode ? '#161616' : '#D2D2D2',

    // border
    brd1: darkMode ? '#2C2928' : '#DBDBDB',

    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
    advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

    //primary colors
    primary1: '#80f683', // 4CA5CE  79CDF1
    primary2: '#00d176', // 8BCEF0
    primary3: '#ce003b', // 4CA5CE
    primary4: '#8BCEF0', // 8BCEF0
    primary5: '#80f683', //

    // color text
    primaryText1: darkMode ? '#fff' : '#000',

    // secondary colors
    secondary1: '#3B6A9C',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    // other
    red1: '#FD4040',
    red2: '#F82D3A',
    red3: '#D60000',
    green1: '#27AE60',
    yellow1: '#FFE270',
    yellow2: '#F3841E',
    blue1: '#3B6A9C',
  };
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? '#000' : '#2F80ED',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  };
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode();

  const themeObject = useMemo(() => theme(darkMode), [darkMode]);

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>;
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`;

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />;
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />;
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />;
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />;
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />;
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />;
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />;
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />;
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />;
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />;
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />;
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />;
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />;
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />;
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />;
  },
};

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Segoe UI', sans-serif;
  font-display: fallback;
}

html,
body {
  margin: 0;
  padding: 0;
}

a {
  color: ${colors(false).blue1};
}

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 18px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
}
`;

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg2};
}


::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}
::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
  background: ${({ theme }) => theme.primary1};
}
::-webkit-scrollbar-track {
  background: rgba(0,0,0,.2);
}

body {
  min-height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${({ theme }) => theme.imageBg}); 
  background-size: cover;
}
.top_sider_main{
  position: relative;
  width: 100%;
  max-width: 500px;
  padding-bottom: 30px;
}
.top_sider_main img{
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.top_sider_main .slick-prev, .slick-next{
  z-index: 1;
}
.top_sider_main .slick-prev {
  left: 5px;
}
.top_sider_main .slick-next {
  right: 5px;
}
.slider_innr_pernt{
  position: relative;
}
.slider_innr_pernt button{
  border: none;
  background: #ffffff3b;
  border-radius: 5px 15px 5px 10px;
  position: absolute;
  padding: 7px 12px;
  right: -2px;
  top: -2px;
}

.soonlabel {
    position: absolute;
    right: 20px;
    top: 5px;
    display: block;
    font-size: 11px;
    font-weight: 800;
    background-color: rgb(0, 209, 118);
    border-radius: 8px;
    border: 2px solid rgb(0, 209, 118);
    padding: 2px 4px;
    color: white;
    box-shadow: rgb(0 209 118) 0px 0px 15px;
}
.header_main {
  border-bottom: 1px solid ${({ theme }) => theme.brd1};
  margin: 0 auto !important;
  background-color: ${({ theme }) => theme.bgdef} !important;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 60px 1fr 20px !important;
    padding: 10px 10px 10px 10px !important;
  `}; 
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  img {
    width: 80px;
  }
  `}; 
}
.lighr_dark_switch {
  // border: 1px solid ${({ theme }) => theme.primary1} !important;
  border: none;
  border-radius: 4px !important;
  background-color: transparent !important;
  position: relative;
}
.borderBefore::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -1px;
  border: 1px solid transparent;
  border-radius: 4px;
  pointer-events: none;
  background: linear-gradient(90deg, ${({ theme }) => theme.primary1} 24.3%, ${({ theme }) => theme.primary2} 98.03%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
}
.conct_wlt {
  background-color: transparent !important;
  width: auto !important;
  min-height: 37px !important;
  border: none !important;
  border-radius: 4px !important;
  font-weight: 800 !important;
  text-transform: uppercase !important;
  color: ${({ theme }) => theme.text6} !important;
  font-size: 16px !important;
  position: relative;
}
.conct_wlt::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -1px;
  border: 1px solid transparent;
  border-radius: 4px;
  pointer-events: none;
  background: linear-gradient(90deg, ${({ theme }) => theme.primary1} 24.3%, ${({ theme }) => theme.primary2} 98.03%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
}
.eth_txt {
  margin: 0 0px 0 10px !important;
}
.eth_txt div {
  background: #0080ff24 !important;
  border-radius: 4px !important;
  margin: 0 !important;
  box-shadow: none !important;
  border: none !important;
}
.main_pages {
  align-items: center;
  justify-content: flex-start;
}
.main_pages .swap_inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 1100px;
}
.left_section {
  margin: 0 !important;
  max-width: 360px !important;
  width: 100% !important;
  background: transparent !important;
  padding: 0 10px !important;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  max-width: 100% !important;
  padding: 0 !important;
  margin: 0 0 20px 0 !important;
`};
}
.left_section_zkdefi {
  padding: 20px !important;
  max-width: 380px !important;
  border: 1px solid ${({ theme }) => theme.brd1} !important;
  border-radius: 5px !important;
  background: transparent !important;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 100% !important;
  `};
}
.brd_box {
  padding-bottom: 15px !important;
  border-bottom: 1px solid ${({ theme }) => theme.brd1} !important;
}
.buy_unw_btn {
  background-color: transparent !important;
  font-size: 14px !important;
  line-height: 18px !important;
  text-align: right !important;
  text-transform: uppercase !important;
  // color: ${({ theme }) => theme.primary1} !important;
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-end !important;
  padding: 0 !important;
  background: linear-gradient(90deg, ${({ theme }) => theme.primary1} 24.3%, ${({ theme }) => theme.primary2} 98.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.footer_containt_grid {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_containt {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 0 !important;
}
.botm_links {
  max-width: 1440px;
  margin-top: 4em;
  margin: 0px auto 0 auto;
}
.swap_tabs_main {
  padding: 0px 20px 20px 20px !important;
  margin: 0px 10px 0px 0px;
  border: 1px solid ${({ theme }) => theme.brd1} !important;
  border-radius: 5px !important;
  background: transparent !important;
  box-shadow: none !important;
  max-width: 680px !important;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    max-width: 100% !important;
  `};
}
.frBorder {
  position: relative;
}
.frBorder::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -1px;
  border: 1px solid transparent;
  border-radius: 4px;
  pointer-events: none;
  background: linear-gradient(90deg, ${({ theme }) => theme.primary1} 24.3%, ${({ theme }) => theme.primary2} 98.03%) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
  display: block;
}
.frBorder input {border: none !important;}
.list-token-manage-button svg > * {
  stroke: ${({ theme }) => theme.primary1};
}
.tabs_links_box {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 1px solid ${({ theme }) => theme.brd1};
  padding-left: 0;
  padding-right: 0;
}
.tabs_links {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.tabs_links:last-child {
  position: relative;
  top: 5px;
}
.tabs_links button svg {margin-top: 0 !important;}
.swap_links {
  color: ${({ theme }) => theme.text3} !important;
  position: relative;
  padding-bottom: 7px;
  width: auto;
}
.swap_links.ACTIVE {
  color: ${({ theme }) => theme.text6} !important;
  position: relative;
}
.swap_links.ACTIVE::before {
  content: "";
  position: absolute;
  bottom: -6px !important;
  height: 2px !important;
  background: linear-gradient(90deg, ${({ theme }) => theme.primary1} 24.3%, ${({ theme }) => theme.primary2} 98.03%) border-box !important;
  width: 100% !important;
}
.trading_text {
  color: ${({ theme }) => theme.text3} !important;
}
.token_box_main {
  background: ${({ theme }) => theme.bg7} !important;
  border-radius: 5px !important;
  padding: 10px 0px 10px 0px !important;
}

.chain_switch_box {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.first_inpt_box {
  background: transparent !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  padding 0 15px
}
/* .first_inpt_box:last-child {align-items: flex-end !important;} 
.first_inpt_box button {
  border: 1px solid ${({ theme }) => theme.brd1} !important;
  border-radius: 5px !important;
  margin-bottom: 10px;
}*/
.first_inpt_box button .token-symbol-container {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: ${({ theme }) => theme.text6} !important;
}
.router_btn {
  padding: 4px 10px !important;
}
.first_inpt_box input {
  width: 150px;
  background-color: transparent !important;
}
.input_box {
  background-color: transparent !important;
}
.max_innr_btn {
  position: absolute;
  bottom: 11px;
  right: 4px;
  background: transparent;
  width: 50px;
  height: 24px;
  display: flex;
  align-items: center;
  border: none !important
}
.border_none {
  border: none !important;
}

.mainInputbx {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(2, minmax(0px, 1fr));

}
.mainInputbx #swap-currency-input {
  align-items: flex-start;
  padding: 10px 10px 10px 10px !important;
}
.mainInputbx #swap-currency-output {
  padding: 10px 10px 10px 0 !important;
  border-left: 1px solid ${({ theme }) => theme.brd1};
  border-radius: 0 !important;
  ${({ theme }) => theme.mediaWidth.upToLarge`
  border: none !important;
  `};
}
.mainInputbx #swap-currency-input .first_inpt_box{align-items: flex-start;}
.mainInputbx #swap-currency-output .first_inpt_box {
  align-items: flex-end;
}
.mainInputbx #swap-currency-output .first_inpt_box input{
  text-align: right;
}
.mainInputbx p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.text3};
}
.mainInputbx p b {
  color: ${({ theme }) => theme.text1};
}
.arow_btn {
  position: absolute;
  cursor: pointer;
  top: 36%;
  left: calc(50% - 18px);
  background: ${({ theme }) => theme.bg7} !important;
  border: 1px solid ${({ theme }) => theme.primary1} !important;
  border-radius: 5px !important;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  z-index: 99;
}
.arow_btn img {width: 20px;}



.trngsn_stng {
  padding-bottom: 15px;
  font-size: 16px !important;
  padding-top: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.brd1};
}
.row_radio_main {
  width: auto;
}
.row_radio_main button {
  border: 1px solid ${({ theme }) => theme.brd1};
  border-radius: 5px;
  width: 70px;
  height: 30px;
  font-weight: 500;
  font-size: 13px;
  max-width: 85px;
  margin-right: 0 !important;
  margin-left: 4px;
  padding: 0 8px;
}
.sttngRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.brd1};
  
  padding: 2px 10px 12px 10px;

}
.sttngRow p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.text3};
  min-width: 160px;
  margin: 0;
  display: flex;
  align-items: center;
}
.sttngRow p span {
  position: relative;
  top: 2px;
}
.frPrnt {
  position: relative;
}
.nmbrInput {
  border: 1px solid ${({ theme }) => theme.brd1};
  border-radius: 5px;
  height: 36px;
  width: 250px;
  text-align: left;
}
.nmbrInput input {text-align: left;}
.absltTxt {
  position: absolute;
  right: 10px;
  top: 11px;
} 
.intfcsttng {
  padding: 10px;
}
.intfcsttng h6 {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.text3};
  min-width: 160px;
  margin-bottom: 15px;
}
.rowBtwn {
  margin-bottom: 10px;
}
.back_file {
  display: flex;
  align-items: center;
}
.back_file img {
  margin-right: 10px;
  position: relative;
    top: 1px;
}
.contct_border {
  border-bottom: 1px solid ${({ theme }) => theme.brd1};
  padding: 0 0 20px 0;
}
.main_popup_connect {
  padding: 20px 30px;
}
.icon_all_sat {
  display: flex;
  align-items: center;
}
.icon_all_sat a {
  width: 44px !important;
  height: 44px !important;
  background: ${({ theme }) => theme.bg7};
  margin: 0 5px;
  border-radius: 50%;
}
.icon_all_sat img {
  width: 44px !important;
  height: 44px !important;
  padding: 10px;
}
.footer_link_text p {
  color: #6C757D;
}
.pl_bdy {
  padding: 20px;
  h6 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.text3};
    margin: 0 0 17px 0;
  }
}
.cardAs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  img {
    margin-bottom: 10px;
    width: 90px;
  }
}
.contct_borderv2 {
  width: 100%;
  padding-top: 15px;
  border: none;
}
.imprtBx {
  border-radius: 5px;
  padding: 15px;
  background: ${({ theme }) => theme.bg7};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  :before {
    content: "";
    background: ${({ theme }) => theme.bg11};
    position: absolute;
    left: calc(50% - 1px);
    top: 0;
    bottom: 0;
    width: 1px;
  }
}
.zupIndex {
  position: relative;
  z-index: 1;
}
.plusBtn {
  background: ${({ theme }) => theme.bg7} !important;
  border: 1px solid ${({ theme }) => theme.primary1} !important;
  border-radius: 5px !important;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
}
.lightCard {
  border-radius: 5px;
  padding: 15px;
  background: ${({ theme }) => theme.bg7};
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.defTxt {
  color: ${({ theme }) => theme.text3};
}
.imprtBx_v2 {
  .max_innr_btn {
    // position: relative !important;
    // margin-top: 8px !important;
    right: -60px;
    bottom: 1px;
    @media screen and (max-width: 575px) {
      right: -15px;
      bottom: 2px;
    }
  }
  .first_inpt_box {
    align-items: flex-start;
    padding: 0;
    p {
      display: none;
    }
  }
  #add-liquidity-input-tokenb {
    .first_inpt_box {
      align-items: flex-end;
      input {
        text-align: right;
      }
    }
  }
}
#add-liquidity-input-tokenb {
  .max_innr_btn {
    right: auto !important;
    left: -60px !important;
    @media screen and (max-width: 575px) {
      right: -15px !important;
      bottom: 2px;
      left: auto !important;
    }
  }
}
.mt-30 {margin-top: 15px;}
.topSlider {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto 20px auto;
  padding: 0 10px !important;
}
.top_sider_main {
  position: relative;
  width: 100%;
  max-width: 500px;
  padding-bottom: 30px;
}
.topSlider .top_sider_main {
  max-width: 100%;
}
.topSlider .top_sider_main .slick-slide img {
  width: 100%;
}
.pd_0 {
  padding: 0 !important;
}
.lightCardp {
  border-radius: 5px;
  padding: 15px 20px;
  background: ${({ theme }) => theme.bg7};
  h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.text3};
    margin: 0 0 15px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.brd1};
  }
}
.tsklstBox {
  p {
    font-weight: 400;
    font-size: 12px;
    color: #888D9B;
    margin-top: 0;
    margin-bottom: 0;
  }
  ul {
    margin: 0;
    padding: 0 0 0 18px;
    li {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: ${({ theme }) => theme.text1};
      margin-top: 12.1px;
    }
  }
}
.clmGrid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
    padding-top: 5px;
  label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.text3};
  }
  h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.text1};
    margin: 0;
  }
}
.cstmpddng {
  padding: 10px;
  border-radius: 5px;
}
${({ theme }) => theme.mediaWidth.upToExtraSm`
  .mainInputbx {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
  .arow_btn {
    top: 46%;
    left: 45%;
  }
  .first_inpt_box input {
    width: 100%;
  }
  .mainInputbx #swap-currency-input {
    align-items: center;
    margin-bottom: 10px !important;
  }
  .mainInputbx #swap-currency-output .first_inpt_box {
    align-items: flex-start;
    margin-top: 10px;
  }
  .mainInputbx #swap-currency-output .first_inpt_box input{
    text-align: left;
  }
  .tabs_links a {
    padding: 13px 10px !important;
  }
  .header_main{ 
    grid-template-columns: 60px 1fr 20px !important;
    padding: 10px 10px 10px 10px !important;
  }
  .sttngRow {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2px 0px 12px 0px;
  }
  .row_radio_main button {
    width: 55px;
    font-size: 12px;
    padding: 0 0px;
  }
  .rowBtwn {
    margin-bottom: 10px;
    flex-direction: column;
  }
  .sttngRow p {
    margin-bottom: 6px;
  }
  .pl_bdy {padding: 15px 0px;}
  .imprtBx {
    flex-direction: column;
    :before {
      display: none;
    }
  }
  .plusBtn {margin: 10px 0;}
  .imprtBx_v2 {
    .input_box {
      width: 100%;
      input {
        width: 100%;
        text-align: left !important;
      }
      button {
        width: 100%;
        span {
          justify-content: flex-start;
          svg {
            margin-left: auto;
          }
        }
      }
      
    }
    .max_innr_btn {
      width: 50px !important;
    }
    .first_inpt_box {justify-content: flex-start !important;    align-items: flex-start !important;}
  }
  
  
`};
`;

import React from 'react';
import styled from 'styled-components';
import { Chain } from '../../constants/chainList';

const ChainSelectorWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ChainButton = styled.button`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  background-color: ${({ theme }) => theme.bg2};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 12px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.bg3};
  }
`;

const ChainLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const ChainName = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.text1};
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.bg1};
  border: 1px solid ${({ theme }) => theme.bg3};
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 0;
  margin: 4px 0 0;
  list-style: none;
  min-width: 120px;
`;

const DropdownItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.bg2};
  }
`;

interface ChainSelectorProps {
  selectedChain: Chain;
  chainList: Chain[];
  onChainSelect: (chain: Chain) => void;
}

const ChainSelector: React.FC<ChainSelectorProps> = ({ selectedChain, chainList, onChainSelect }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleChainSelect = (chain: Chain) => {
    onChainSelect(chain);
    setIsOpen(false);
  };

  return (
    <ChainSelectorWrapper>
      <ChainButton onClick={toggleDropdown}>
        <ChainLogo src={selectedChain.icon} alt={selectedChain.name} />
        <ChainName>{selectedChain.name}</ChainName>
      </ChainButton>
      {isOpen && (
        <DropdownList>
          {chainList.map((chain) => (
            <DropdownItem key={chain.id} onClick={() => handleChainSelect(chain)}>
              <ChainLogo src={chain.icon} alt={chain.name} />
              <ChainName>{chain.name}</ChainName>
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </ChainSelectorWrapper>
  );
};

export default ChainSelector;
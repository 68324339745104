import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';

export function useSwitchNetwork() {
  const { connector } = useWeb3React();

  const switchNetwork = useCallback(async () => {
    if (!connector) {
      console.error('No connector found');
      return;
    }

    try {
      const provider = await connector.getProvider();
      if (provider && provider.request) {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${parseInt(process.env.REACT_APP_CHAIN_ID ?? '1', 10).toString(16)}` }],
        });
      }
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          const provider = await connector.getProvider();
          if (provider && provider.request) {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${parseInt(process.env.REACT_APP_CHAIN_ID ?? '1', 10).toString(16)}`,
                  rpcUrls: [process.env.REACT_APP_NETWORK_URL],
                  chainName: process.env.REACT_APP_CHAIN_NAME,
                  nativeCurrency: {
                    name: process.env.REACT_APP_CURRENCY_NAME,
                    symbol: process.env.REACT_APP_CURRENCY_SYMBOL,
                    decimals: 18
                  },
                  blockExplorerUrls: [process.env.REACT_APP_BLOCK_EXPLORER_URL]
                },
              ],
            });
          }
        } catch (addError) {
          console.error('Failed to add the network', addError);
        }
      }
      console.error('Failed to switch to the network', switchError);
    }
  }, [connector]);

  return switchNetwork;
}
import React, { useState, useEffect, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { network } from '../../connectors';
import { useEagerConnect, useInactiveListener } from '../../hooks';
import { NetworkContextName } from '../../constants';
import { useLocation } from 'react-router-dom';
import { chainList } from '../../constants/chainList';
import { useSwitchNetwork } from '../../hooks/useSwitchNetwork';


import Loader from '../Loader';

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20rem;
`;

const Message = styled.h2`
  color: ${({ theme }) => theme.secondary1};
`;

export default function Web3ReactManager({ children }: { children: JSX.Element }) {
  const { t } = useTranslation();
  const { active, chainId } = useWeb3React();
  const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React(NetworkContextName);
  const location = useLocation();
  const isBridgePage = location.pathname.includes('/bridge');

  const triedEager = useEagerConnect();
  const switchNetwork = useSwitchNetwork();

  const isChainSupported = useMemo(() => {
    if (!chainId) return true; // Consider it supported if not connected 
    if (isBridgePage) {
      return chainList.some(chain => chain.chainid === chainId);
    } else {
      return chainId === parseInt(process.env.REACT_APP_CHAIN_ID ?? '1');
    }
  }, [chainId, isBridgePage]);

  useEffect(() => {
    if (triedEager && !networkActive && !networkError && !active) {
      activateNetwork(network);
    }
  }, [triedEager, networkActive, networkError, activateNetwork, active]);

  useEffect(() => {
    if (active && !isChainSupported && !isBridgePage) {
      switchNetwork();
    }
  }, [active, isChainSupported, isBridgePage, switchNetwork]);

  useInactiveListener(!triedEager);

  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(true);
    }, 600);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!triedEager) {
    return null;
  }

  if (!active && networkError) {
    return (
      <MessageWrapper>
        <Message>{t('unknownError')}</Message>
      </MessageWrapper>
    );
  }

  // Show children (app content) if not connected or on a supported network
  if (!active || isChainSupported) {
    return children;
  }

  // Only show the "Connecting to the correct network..." message when actually trying to switch
  if (active && !isChainSupported) {
    return (
      <MessageWrapper>
        <Message>
          {t('Connecting to the correct network...')}
        </Message>
      </MessageWrapper>
    );
  }

  // Fallback loader
  return showLoader ? (
    <MessageWrapper>
      <Loader />
    </MessageWrapper>
  ) : null;
}
import { useState, useEffect } from 'react';
import { JSBI } from 'sdk';
import { ethers } from 'ethers';
import { chainList } from '../constants/chainList';
import OriginBridgeABI from '../constants/abis/OriginBridge.json';
import DestinationBridgeABI from '../constants/abis/DestinationBridge.json';

export const useBridgeLimits = () => {
  const [limits, setLimits] = useState<{
    [chainId: string]: { minAmount: JSBI; maxAmount: JSBI }
  }>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAmountLimits = async () => {
      setIsLoading(true);
      const results: { [chainId: string]: { minAmount: JSBI; maxAmount: JSBI } } = {};

      for (const chain of chainList) {
        const provider = new ethers.providers.JsonRpcProvider(chain.rpcurl);
        const bridgeContract = new ethers.Contract(
          chain.bridge,
          chain.id === 'neox' ? DestinationBridgeABI : OriginBridgeABI,
          provider
        );

        try {
          const [fetchedMinAmount, fetchedMaxAmount] = await Promise.all([
            bridgeContract.minAmount(),
            bridgeContract.maxAmount()
          ]);

          results[chain.id] = {
            minAmount: JSBI.BigInt(fetchedMinAmount.toString()),
            maxAmount: JSBI.BigInt(fetchedMaxAmount.toString())
          };
        } catch (error) {
          console.error(`Failed to fetch min/max amounts for ${chain.id}:`, error);
          results[chain.id] = { minAmount: JSBI.BigInt(0), maxAmount: JSBI.BigInt(0) };
        }
      }

      setLimits(results);
      setIsLoading(false);
    };

    fetchAmountLimits();
  }, []);

  return (chainId: string) => ({
    minAmount: limits[chainId]?.minAmount ?? JSBI.BigInt(0),
    maxAmount: limits[chainId]?.maxAmount ?? JSBI.BigInt(0),
    isLoading
  });
};